import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import { capitalCase } from 'change-case'
import Layout from '../components/Layout'
import Signature from '../components/Signature'
import '../components/all.sass'

export default function MilestoneReviewTemplate({ pageContext, data }) {
  const { files } = data
  const { parentPathRegex } = pageContext
  const filePathParts = parentPathRegex.split('/')
  const productName = filePathParts[1]
  const designPhase = filePathParts[2]
  const filePath = `${productName}/${designPhase}/milestone-review`
  const designPhaseChecklist = {
    'Initial Concept': [
      'Device Concept is Produceable, Saleable, and Classified Correctly',
    ],
    'Design Requirements': [
      'Risks Have Been Accurately Identified in the Risk Analysis',
      'The Design Requirements Support and Relate to the Marketing Requirements',
    ],
    'Specification Development': [
      'The Design Specifications Supprt and Relate to the Design Requirements',
    ],
    'Design Activities': [
      'The Material Specification and MSDS Sheets Match the Design Specifications',
      'The Drawing Specifications Match the Design Speicifcations',
      'The Risk/FMECA Supprts the Hazard Analysis',
    ],
    'Design Verification': [
      'The Verification Test Results Support the Design Specifications',
      'The Inputs and Outputs are Still Valid After Review',
    ],
    'Design Validation': [
      'The Design Validation Supports the Design Requirments',
      'The Inputs and Outputs are Still Valid After Review',
    ],
    'Pre-Production': [
      'The Pre-Production Testing from Productized Run Match Verification Testing',
      'The Quality Plan Supports Continued Improvement of the Hazard Analysis',
      'The Quality Plan Supports Quality Requirments',
    ],
    'Design Release': [
      'All documents in This Design History File Have Been Reviewed and Approved',
      'The Inputs and Outputs are Still Valid After Review',
      'List initial part numbers to release in Mas100',
    ],
  }
  const today = new Date()
  const date =
    today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()

  return (
    <Layout title="Milestone Review" filePath={filePath}>
      <div className="section sectionbody">
        <h3 className="title is-3">Design Review: {designPhase}</h3>
        <h5 className="title is-5">Review Date: {date}</h5>
        <h3 className="title is-3">1. Documents Under Review</h3>
        <p>
          This section lists the documents being examined in this design review
        </p>
      </div>
      <section className="section sectionbody">
        <table className="table is-striped is-narrow is-hoverable">
          <th>Documents Name</th>
          <th>Disposition</th>
          <th>Recommendation</th>
          {files.edges.map((edge) => (
            <tr>
              <td>
                <Link
                  to={`/${edge.node.childMarkdownRemark.fields.filePath}`}
                  replace
                >
                  {capitalCase(edge.node.childMarkdownRemark.fields.filePath)}
                </Link>
              </td>
              <td>
                <div className="select">
                  <select>
                    <option disabled selected value>
                      {' '}
                      Select an Option{' '}
                    </option>
                    <option>Fix Required Immediately</option>
                    <option>Fix Required Later</option>
                    <option>Acceptable As Is</option>
                  </select>
                </div>
              </td>
              <td>
                <textarea />
              </td>
            </tr>
          ))}
        </table>
      </section>
      <section className="section sectionbody">
        <h3 className="title is-3">2. Conclusion</h3>
        <div className="align-content-center block">
          <p>
            As a result of this Design Review, it has been concluded that the
            reviewed documents are
          </p>
          <div className="select">
            <select>
              <option>Not Approved: Further Review Required</option>
              <option>Approved with Changes</option>
              <option>Approved As Is</option>
            </select>
          </div>
        </div>
      </section>
      <section className="section sectionbody">
        <h3 className="title is-3">3. Approvals</h3>
        <p>
          By signing below, you agree that the documents are{' '}
          <strong>"Approved"</strong> and:
        </p>
        <div>
          <dl>
            {designPhaseChecklist[designPhase].map((item) => (
              <dd>{item}</dd>
            ))}
          </dl>
        </div>
        <section className="section sectionbody">
          <Signature caption="Q/A" hasDate />
          <Signature caption="ENG" hasDate />
          <Signature caption="Independent Reviewer" hasDate />
        </section>
      </section>
    </Layout>
  )
}

//query all files in the parent directory
export const query = graphql`
  query MarkdownQueryMilestone($parentPathRegex: String) {
    files: allFile(filter: { relativePath: { regex: $parentPathRegex } }) {
      edges {
        node {
          childMarkdownRemark {
            fields {
              filePath
            }
          }
        }
      }
    }
  }
`
